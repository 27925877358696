<template>
  <div class="content-sb">
    <div class="title-admin">
      <h3>{{ title }}</h3>
      <p>Найдено {{ counter }} записей</p>
    </div>
    <router-link v-if="button.link !== '' && button.title !== ''" class="btn-blue" :to="button.link">
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#DDEDFE"/>
          <rect x="3" y="10" width="2" height="12" rx="1" transform="rotate(-90 3 10)" fill="#246CB7"/>
          <rect x="10" y="15" width="2" height="12" rx="1" transform="rotate(180 10 15)" fill="#246CB7"/>
        </svg>
        {{ button.title }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'HeaderAdminPages',
  props: {
    title: {
      type: String,
      required: true,
      default: 'Заголовок страницы'
    },
    counter: {
      type: Number,
      required: false,
      default: 0
    },
    button: {
      type: Object,
      required: false,
      default: function () {
        return {
          title: '',
          link: ''
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.title-admin {
  display: flex;
  align-items: baseline;
  h3 {
    font-weight: 600;
    font-size: 1.37rem;
    color: #1F3245;
  }
  p {
    margin-left: 1.56rem;
    font-size: 0.875rem;
    color: #9D9D9D;
  }
}
.btn-blue {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.125rem;
    margin-right: 0.56rem;
  }
}
.content-sb {
  padding-bottom: 2rem;
  border-bottom: 1px solid #D5D6D9;
}
</style>
