<template>
  <div class="wrapper-drop-menu">
    <div @click="statusPopup = !statusPopup" class="drop-menu" :class="{ 'active-btn' : statusPopup }">
      <svg  viewBox="0 0 11 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.125977 1.14575C0.125977 0.81307 0.212565 0.557861 0.385742 0.380127C0.558919 0.202393 0.80957 0.113525 1.1377 0.113525C1.47038 0.113525 1.72331 0.20695 1.89648 0.393799C2.06966 0.57609 2.15625 0.826742 2.15625 1.14575C2.15625 1.46932 2.06738 1.72681 1.88965 1.91821C1.71647 2.10506 1.46582 2.19849 1.1377 2.19849C0.80957 2.19849 0.558919 2.10506 0.385742 1.91821C0.212565 1.73136 0.125977 1.47388 0.125977 1.14575ZM4.48145 1.14575C4.48145 0.81307 4.56803 0.557861 4.74121 0.380127C4.91439 0.202393 5.16504 0.113525 5.49316 0.113525C5.82585 0.113525 6.07878 0.20695 6.25195 0.393799C6.42513 0.57609 6.51172 0.826742 6.51172 1.14575C6.51172 1.46932 6.42285 1.72681 6.24512 1.91821C6.07194 2.10506 5.82129 2.19849 5.49316 2.19849C5.16504 2.19849 4.91439 2.10506 4.74121 1.91821C4.56803 1.73136 4.48145 1.47388 4.48145 1.14575ZM8.83691 1.14575C8.83691 0.81307 8.9235 0.557861 9.09668 0.380127C9.26986 0.202393 9.52051 0.113525 9.84863 0.113525C10.1813 0.113525 10.4342 0.20695 10.6074 0.393799C10.7806 0.57609 10.8672 0.826742 10.8672 1.14575C10.8672 1.46932 10.7783 1.72681 10.6006 1.91821C10.4274 2.10506 10.1768 2.19849 9.84863 2.19849C9.52051 2.19849 9.26986 2.10506 9.09668 1.91821C8.9235 1.73136 8.83691 1.47388 8.83691 1.14575Z" fill="#5A79A8"/>
      </svg>
    </div>
    <div v-if="statusPopup" @mouseleave="statusPopup = false" class="drop-list">
      <router-link :to="editLink" v-if="editLink" class="drop-list__item">
        Редактировать
      </router-link>
      <p @click="clickItem('edit')" class="drop-list__item" v-else>
        Редактировать
      </p>
      <p @click="clickItem('del')" class="drop-list__item">
        Удалить
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropList',
  data () {
    return {
      statusPopup: false
    }
  },
  props: {
    idItem: {
      type: Number,
      required: true
    },
    editLink: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    clickItem (value) {
      this.$emit('clickItem', { value, id: this.idItem })
      this.statusPopup = false
    }
  }
}
</script>

<style scoped lang="scss">
.drop-menu{
  width: 2.18rem;
  height: 2.18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #EEF4FA;
  cursor: pointer;
  border-radius: 8px;

  &:hover{
    background: #EEF4FA;
  }

  svg{
    width: 0.68rem;
    height: 0.18rem;
  }
}
.wrapper-drop-menu{
  position: relative;
  user-select: none;
}
.drop-list{
  position: absolute;
  left: -6.5rem;
  top: 3rem;
  padding: 1.31rem 0.875rem 0.68rem 0.875rem;
  background: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #EEF4FA;
  z-index: 3;
  &__item {
    font-size: 0.875rem;
    color: #003E78;
    padding: 0.75rem;
    cursor: pointer;
    &:hover{
      background: rgba(36, 108, 183, 0.1);
      border-radius: 3px;
    }
  }
}

.active-btn{
  background: #EEF4FA;
}
</style>
