<template>
      <!-- <div class="pagination__left">
        <a href="#" v-if="hasPrev()" @click.prevent="changePage(prevPage)">Предыдущая</a>
      </div> -->
      <div v-if="total > perPage" class="pagination">
        <ul>
          <li v-if="hasFirst()"><a href="#" @click.prevent="changePage(prevPage)">&lt;</a></li>
          <li v-if="hasFirst()" class="dots">...</li>
          <li v-for="(page, key) in pages" :key="`page${key}`">
            <a href="#" @click.prevent="changePage(page)" :class="{ current: current == page }">
              {{ page }}
            </a>
          </li>
          <li v-if="hasLast()" class="dots">...</li>
          <li v-if="hasLast()"><a href="#"  @click.prevent="changePage(nextPage)">&gt;</a></li>
        </ul>
      </div>
      <!-- <div class="pagination__right">
        <a href="#" v-if="hasNext()" @click.prevent="changePage(nextPage)">Следующая</a>
      </div> -->
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    current: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 9
    },
    pageRange: {
      type: Number,
      default: 2
    }
  },
  computed: {
    pages () {
      const pages = []
      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i)
      }

      return pages
    },
    rangeStart () {
      const start = this.current - this.pageRange

      return (start > 0) ? start : 1
    },
    rangeEnd () {
      const end = this.current + this.pageRange

      return (end < this.totalPages) ? end : this.totalPages
    },
    totalPages () {
      return Math.ceil(this.total / this.perPage)
    },
    nextPage () {
      return this.current + 1
    },
    prevPage () {
      return this.current - 1
    }
  },
  methods: {
    hasFirst () {
      return this.rangeStart !== 1
    },
    hasLast () {
      return this.rangeEnd < this.totalPages
    },
    hasPrev () {
      return this.current > 3
    },
    hasNext () {
      return this.current < this.totalPages
    },
    changePage (page) {
      this.$emit('page-changed', page)
    }
  }
}
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  justify-content: center;
}

.pagination a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.1875rem;
  height: 2.1875rem;

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.375rem;

  color: #246CB7;
  border: 1px solid #d2dde8;
  border-radius: 3px;

  margin: 0 0.375rem;
  transition: all .2s ease-in-out;
}

.pagination a.current {
  background: #d1e0ef;
}

@media (hover) {
   .pagination a:hover {
      background: #d1e0ef;
      border: 1px solid #246CB7;
   }
}

.pagination ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination li {
  display: inline-block;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dots {
    position: relative;
    bottom: .75rem;
  }
}

</style>
